/**
 * Talbit "Beyond"  Base layer CSS. 
 * Styles all the common stuff, like fonts, colors, buttons etc.
 */

body {
  background-color: #f7f5f5 !important;
  font-family: "Sora", sans-serif !important;
  color: #205072 !important;
}

#root {
  will-change: auto !important;
  transform: none !important;
}

/* Override webkit scrollbar style */

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(17, 153, 142, 0.8);
}

/* Font styles */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif !important;
  font-weight: 600 !important;
  color: #205072;
  letter-spacing: 0.015rem;
}

h5 {
  font-size: 1.12rem;
  line-height: 1.5rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

p {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #205072;
  white-space: pre-line;
  white-space: pre-wrap;
}

li {
  color: #205072;
  font-family: "DM Sans", sans-serif;
}

strong {
  color: #205072;
  font-size: 1rem;
}

a {
  color: #11998e;
  cursor: pointer !important;
}

a:hover {
  color: #11998e;
}

a:not([href]):not([class]) {
  color: #11998e !important;
}

/* Truncate helper for longer texts */

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Table styles */

table {
  color: #205072 !important;
  border-spacing: 0;
}

thead {
  background-color: #ebebeb;
}

tbody {
  background: #fff !important;
}
th,
td {
  border: 1px solid #cccccc !important;
  padding: 0.5em 1em;
}

.error-span {
  color: red;
}

/* Input styles */

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

input[type="search"] {
  margin: 0;
  padding: 20px 12px !important;
  color: #205072;
  font-family: "Sora";
  border: 2px solid transparent;
  outline: 0;
  border-radius: 20px;
  background: white;
  transition: all 100ms ease !important;
}

input[type="filter"] {
  margin: 0;
  padding: 10px 16px !important;
  color: #205072;
  font-family: "Sora";
  border: 2px solid transparent;
  outline: 0;
  border-radius: 20px;
  background: #f5f5f5;
  transition: all 100ms ease !important;
  font-size: 14px !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="name"],
input[type="number"],
input[type="number"],
textarea {
  margin: 0;
  padding: 15px 15px 15px 15px;
  color: #205072;
  font-family: "Sora" !important;
  box-shadow: rgba(117, 117, 117, 0.2) 3px 3px 13px;
  border: 2px solid transparent;
  outline: 0;
  border-radius: 20px;
  background: white;
  transition: all 100ms ease !important;
}

input.error,
textarea.error {
  border: 2px solid rgba(201, 70, 61, 0.3);
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="name"]:focus,
textarea:focus {
  box-shadow: rgba(64, 173, 91, 0.2) 3px 3px 13px 5px;
  -webkit-transition: all 100ms ease !important;
  transition: all 100ms ease !important;
}

input::placeholder {
  color: #9c9c9c;
}

textarea::placeholder {
  color: #9c9c9c;
}

/* Range input override */

input[type="range"] {
  height: 24px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  transition: all 100ms ease !important;
  background-color: transparent !important;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;

  box-shadow: 0px 0px 0px #969696;
  background: #11998e;
  border-radius: 20px;
  border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 3px #b3b3b3;
  border: 0px solid #000000;
  height: 17px;
  width: 16px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #11998e;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #969696;
  background: #11998e;
  border-radius: 20px;
  border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 3px #b3b3b3;
  border: 0px solid #000000;
  height: 17px;
  width: 16px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #11998e;
  border: 0px solid #000000;
  border-radius: 40px;
  box-shadow: 0px 0px 0px #969696;
}
input[type="range"]::-ms-fill-upper {
  background: #11998e;
  border: 0px solid #000000;
  border-radius: 40px;
  box-shadow: 0px 0px 0px #969696;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 3px #b3b3b3;
  border: 0px solid #000000;
  height: 17px;
  width: 16px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #11998e;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #11998e;
}

.select-date input[type="text"] {
  padding: 6px 20px !important;
  font-size: 1em !important;
  width: 7rem;
  border-radius: 10px;
}
.select-date-sprint input[type="text"] {
  padding: 6px 20px !important;
  font-size: 1em !important;
  width: 8.5rem;
  border-radius: 10px;
}

/* Button overrides */

.btn {
  border-radius: 20px;
  outline: none;
  border: none;
  font-family: "Sora", sans-serif;
  font-weight: 600;
  padding: 1em;
  -webkit-transition: all 300ms ease !important;
  transition: all 300ms ease !important;
  box-shadow: rgba(64, 173, 91, 0.15) 3px 3px 13px 5px;
}

.btn-sm {
  border-radius: 20px !important;
  outline: none;
  border: none;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  -webkit-transition: all 300ms ease !important;
  transition: all 300ms ease !important;
  box-shadow: rgba(64, 173, 91, 0.15) 3px 3px 13px 5px;
}

.btn-select-date {
  margin-right: 32px;
  border-radius: 10px !important;
  padding: 8px 25px !important;
  border: none !important;
  outline: 0 !important;
}

/* Talbit primary color button */
.btn-primary {
  background-color: #11998e !important;
}

.btn-outline-primary {
  padding: 0.35rem 0.6rem !important;
  font-size: 0.83rem !important;
  color: #11998e;
  border-radius: 12px !important;
  border: 1px solid #11998e !important;
  box-shadow: none;
}

.btn-outline-primary:hover {
  background-color: #11998e;
}

.btn-primary:hover {
  background-color: #11998e !important;
}

.btn-primary:focus,
.btn-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-primary.active {
  background-color: #11998e !important;
}

.btn-primary.dropdown-toggle {
  background-color: #4285f4 !important;
}

.btn-primary.dropdown-toggle:hover,
.btn-primary.dropdown-toggle:focus {
  background-color: #5a95f5 !important;
}

.btn-outline-primary {
  padding: 0.35rem 0.6rem !important;
  font-size: 0.83rem !important;
  color: #11998e;
  border-radius: 12px !important;
  border: 1px solid #11998e !important;
  box-shadow: none;
  word-wrap: break-all;
}

.btn-outline-primary:hover {
  background-color: #11998e;
}

/* Talbit gradient button */
.btn-talbit-gradient {
  color: white !important;
  background: linear-gradient(to left, #38ef7d, #11998e);
}

.btn-talbit-gradient:hover {
  background-color: #38ef7d !important;
}

.btn-talbit-gradient:focus,
.btn-talbit-gradient:active,
.btn-talbit-gradient.active {
  background-color: #11998e !important;
}

.btn-primary.dropdown-toggle {
  background-color: #4285f4 !important;
}

.btn-primary.dropdown-toggle:hover,
.btn-primary.dropdown-toggle:focus {
  background-color: #5a95f5 !important;
}

/*Succes button */
.btn-success {
  background-color: #00c851 !important;
}
.btn-success:hover {
  background-color: #00e25b !important;
}
.btn-success:focus,
.btn-success:active,
.btn-success.active {
  background-color: #007c32 !important;
}
.btn-success.dropdown-toggle {
  background-color: #00c851 !important;
}
.btn-success.dropdown-toggle:hover,
.btn-success.dropdown-toggle:focus {
  background-color: #00e25b !important;
}

.btn-default {
  background-color: #11998e !important;
}

.btn-danger {
  background-color: #ff3547 !important;
}
.btn-danger:hover {
  background-color: #ff4f5e !important;
}
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
  background-color: #e80015 !important;
}
.btn-danger.dropdown-toggle {
  background-color: #ff3547 !important;
}
.btn-danger.dropdown-toggle:hover,
.btn-danger.dropdown-toggle:focus {
  background-color: #ff4f5e !important;
}

.btn-outline-danger {
  border: 2px solid #ff3547;
  color: #ff3547 !important;
  background-color: transparent;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger:active:focus,
.btn-outline-danger.active {
  background-color: transparent;
  color: #ff3547;
  border-color: #ff3547;
}

.btn-warning {
  background-color: #ff8f1e !important;
  color: #fff !important;
}
.btn-warning:hover {
  background-color: #ff941a !important;
}
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
  background-color: #b35f00 !important;
}
.btn-warning.dropdown-toggle {
  background-color: #ff8800 !important;
}
.btn-warning.dropdown-toggle:hover,
.btn-warning.dropdown-toggle:focus {
  background-color: #ff941a !important;
}

.btn-outline-warning {
  border: 2px solid #ff8f1e;
  color: #ff8f1e !important;
  background-color: transparent;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning:active:focus,
.btn-outline-warning.active {
  background-color: transparent;
  color: #ff8800;
  border-color: #ff8800;
}

.btn-success {
  background-color: #00c851 !important;
}
.btn-success:hover {
  background-color: #00e25b !important;
}
.btn-success:focus,
.btn-success:active,
.btn-success.active {
  background-color: #007c32 !important;
}
.btn-success.dropdown-toggle {
  background-color: #00c851 !important;
}
.btn-success.dropdown-toggle:hover,
.btn-success.dropdown-toggle:focus {
  background-color: #00e25b !important;
}

.btn-outline-success {
  border: 2px solid #00c851;
  color: #00c851 !important;
  background-color: transparent;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success:active:focus,
.btn-outline-success.active {
  background-color: transparent;
  color: #00c851;
  border-color: #00c851;
}

.btn-info {
  background-color: #33b5e5 !important;
}
.btn-info:hover {
  background-color: #4abde8 !important;
}
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  background-color: #178ab4 !important;
}
.btn-info.dropdown-toggle {
  background-color: #33b5e5 !important;
}
.btn-info.dropdown-toggle:hover,
.btn-info.dropdown-toggle:focus {
  background-color: #4abde8 !important;
}

.btn-linkedin {
  color: #fff !important;
  background-color: #4875b4 !important;
}
.btn-info:hover {
  background-color: #4abde8 !important;
}
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  background-color: #178ab4 !important;
}
.btn-info.dropdown-toggle {
  background-color: #33b5e5 !important;
}
.btn-info.dropdown-toggle:hover,
.btn-info.dropdown-toggle:focus {
  background-color: #4abde8 !important;
}

.btn-outline-info {
  border: 2px solid #33b5e5;
  color: #33b5e5 !important;
  background-color: transparent;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info:active:focus,
.btn-outline-info.active {
  background-color: transparent;
  color: #33b5e5;
  border-color: #33b5e5;
}

.btn-default {
  background-color: #2bbbad !important;
}
.btn-default:hover {
  background-color: #30cfc0 !important;
}
.btn-default:focus,
.btn-default:active,
.btn-default.active {
  background-color: #1d7d73 !important;
}
.btn-default.dropdown-toggle {
  background-color: #2bbbad !important;
}
.btn-default.dropdown-toggle:hover,
.btn-default.dropdown-toggle:focus {
  background-color: #30cfc0 !important;
}

.btn-outline-default {
  border: 2px solid #2bbbad;
  color: #2bbbad !important;
  background-color: transparent;
}
.btn-outline-default:hover,
.btn-outline-default:focus,
.btn-outline-default:active,
.btn-outline-default:active:focus,
.btn-outline-default.active {
  background-color: transparent;
  color: #2bbbad;
  border-color: #2bbbad;
}

.delete-btn {
  color: #ff5724;
  background-color: transparent;
  border: none;
  font-weight: bold;
}

.edit-btn {
  color: #fd9833;
  background-color: transparent;
  border: none;
  font-weight: bold;
}

.add-btn {
  color: #11998e;
  background-color: transparent;
  border: none;
  font-weight: bold;
  padding: 16px;
}

/* Talbit Card Styles */

.card {
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 20px;
  box-shadow: rgba(117, 117, 117, 0.1) 0px 13px 18px;
}

.MuiDialog-paper {
  margin: 32px;
  position: relative;
  overflow-y: auto;
  background-color: #fafafa !important;
}

/* Common styles for dialogs */

.modal-header {
  display: block;
  width: 100%;
  height: 90px;
}

.modal-header .close-button {
  color: #fff;
}

/* Talbit gradients and colors */

.green-gradient {
  background: linear-gradient(to left, #38ef7d, #11998e);
}

.yellow-gradient {
  background: linear-gradient(
    90deg,
    rgba(249, 206, 0, 1) 0%,
    rgba(251, 255, 0, 1) 100%
  );
}

.red-gradient {
  background: linear-gradient(
    90deg,
    rgba(246, 30, 30, 1) 0%,
    rgba(255, 96, 0, 1) 100%
  );
}

.orange-gradient {
  background: linear-gradient(
    90deg,
    rgba(249, 93, 0, 1) 0%,
    rgba(255, 177, 0, 1) 100%
  );
}

.grey-overlay {
  filter: invert(91%) sepia(0%) saturate(1350%) hue-rotate(259deg)
    brightness(69%) contrast(88%);
}

/* Common text styles */

.grey-text {
  color: #6c6c6c;
}

.green-text {
  color: #11998e;
  cursor: pointer;
}

.yellow-text {
  color: rgba(230, 190, 0, 1);
}

.red-text {
  color: rgba(246, 30, 30, 1);
}

/* Common avatar styles */

.autocomplete img.avatar {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40x;
  border: 3px solid #fff;
  box-shadow: 5px 5px 19px #e8e8e8, -5px -5px 19px #ffffff;
}

.autocomplete .user-container {
  color: #205072 !important;
}

.MuiAutocomplete-popper {
  z-index: 999999 !important;
}

/* Other overrides */

.badge-success {
  background-color: #11998e !important;
}

/* Fix details dialogs zIndex */

.react-datepicker,
.react-datepicker-popper {
  z-index: 999999999999999 !important;
}

.goal-details,
.objective-details {
  z-index: 120 !important;
}

.team-objective-details {
  z-index: 999 !important;
}

.kr-details {
  z-index: 9999 !important;
}

.kr-details-team-view {
  z-index: 990 !important;
}

.confetti-overlay {
  z-index: 9999999 !important;
  position: absolute !important;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: none !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  background-color: #fff !important;
  border-radius: 20px !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #11998e !important;
  border-color: #11998e !important;
}

.empty-card {
  opacity: 0.8;
}

p.bigger {
  font-size: 105%;
}

.MuiSlider-markLabel {
  top: 26px;
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  font-size: 0.8rem !important;
  transform: translateX(-50%);
  font-weight: 500;
  line-height: 1.43;
  white-space: normal !important;
}

.MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.MuiFilledInput-underline:hover:before {
  border-bottom: none !important;
}

.MuiFilledInput-underline:after {
  border-bottom: none !important;
}
.MuiDialog-root {
  z-index: 99999998 !important;
}

.MuiAutocomplete-popper {
  z-index: 9999999999 !important;
}

.MuiTooltip-popper {
  z-index: 9999999999 !important;
}

.MuiSnackbar-root {
  z-index: 999999999 !important;
}

.MuiInputBase-root {
  color: #205072;
  font-family: "Sora";
  outline: 0;
  border-radius: 20px !important;
}

.MuiInputBase-root:focus {
  box-shadow: rgba(64, 173, 91, 0.2) 3px 3px 13px 5px !important;
  -webkit-transition: all 100ms ease !important;
  transition: all 100ms ease !important;
}

#react-modal-sheet-highlight {
  position: relative !important;
}

.autocomplete > .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-top: 0px !important;
  padding-left: 8px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.react-datepicker {
  z-index: 999999;
}

.btn-outlined {
  padding: 0.4rem 1rem !important;
  font-size: 0.96rem !important;
  color: #11998e;
  border-radius: 12px !important;
  border: 2px solid #11998e !important;
  box-shadow: none;
}

.btn-outlined-small {
  padding: 0.3rem 0.8rem !important;
  font-size: 0.8rem !important;
  color: #11998e;
  border-radius: 12px !important;
  border: 1px solid #11998e !important;
  box-shadow: none;
}

.btn-outlined:hover {
  background-color: #11998e;
  color: #fff;
}

.btn-outlined-small:hover {
  background-color: #11998e;
  color: #fff;
}

.react-datepicker__aria-live {
  display: none !important;
}